<template>
  <div style="min-height:100vh;">
    <div class="header">
      <van-row gutter="10" >
        <van-col  span="12" @click.native="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back`_icon'"
            style="height: 25px;margin:.3rem 0 0 .2rem;"
          />
          <span class="title-text">行程详情</span>
        </van-col>
        <van-col span="12" style="padding: 1px .2rem 0 0">
          <div class="title-text" style="margin-top:.2rem;text-align:right" @click="toTousuPage">投诉</div>
        </van-col>
      </van-row>
    </div>
    <div style="height:90vh;overflow-y:scroll;padding:.2rem .2rem 4rem .2rem;margin-top:.1rem">
      <van-pull-refresh v-model="loading" @refresh="refresh">
        <card style="margin:30px 0 100px">
          <template #content>
            <guideInfo :guider="guide" :tour="currentTravelList"></guideInfo>
            <van-divider/>
            <tourInfoTop :showNodesButton="false" :tour="currentTravelList"></tourInfoTop>
            <van-row class="row" style="margin-top: 10px">
              出发日期：{{ currentTravelList.start_date }}
            </van-row>
            <van-row class="row"> 团队人数：{{ currentTravelList.tourists_count == null? 0: currentTravelList.tourists_count }} </van-row>
            <van-divider/>
            <div class="circle-left"></div>
            <div class="circle-right"></div>
            <van-row>
              <span class="tour_title">
                {{currentTravelList.travel_plan.plan_name}}
              </span>
            </van-row>
            <van-steps :active="currentNodeIdx" active-color="#395EE8" direction="vertical" active-icon="clock">
              <van-step v-for="(item, index) in currentTravelList.travel_list_nodes" :key="index">
                <van-row>
                  <van-col span="20" >
                    <span :style="{'font-weight':600, 'color': currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                      {{item.node_name}}
                    </span>
                  </van-col>
                <van-col span="4" style="text-align:right;padding-left:5px">
                    <van-tag round color="#13C2C220" text-color="#13C2C2" style="width:50px"
                      size="medium" v-if="index < currentTravelList.travel_checkins.length 
                        && currentTravelList.travel_checkins.length > 0">已完成</van-tag>
                    <van-tag round color="#3672F920" text-color="#3672F9" 
                      size="medium" v-if="index === currentTravelList.travel_checkins.length && currentTravelList.status == 1" style="width:50px">进行中</van-tag>
                    <van-tag round  
                      size="medium" type="danger" color="#6f6f6f23" text-color="#979797"
                      v-if="index > currentNodeIdx - 1 && currentTravelList.status == 3" 
                      style="width:50px">已取消</van-tag>
                  </van-col>
                </van-row>
                <van-row :style="{'margin-top':'.1rem', 'color':currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                  <span>{{item.node_description}}</span>
                </van-row>
                <van-row :style="{'margin-top':'.1rem', 'color':currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                  <span>开始：{{item.start_time}}</span>
                </van-row>
                <van-row :style="{'margin-top':'.1rem', 'color':currentTravelList.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                  <span>结束：{{item.end_time}}</span>
                </van-row>
              </van-step>
            </van-steps>
            <van-cell v-if="currentTourBackup['id'] != null" is-link title="我的备案" :to="`/tour/backupInfo/${currentTravelList.id}`"/>
            <van-cell v-if="currentTourBackup['id'] == null" is-link title="添加备案" :to="`/tour/backup/${currentTravelList.id}`"/>
          </template>
        </card>
      </van-pull-refresh>
    </div>
    
    <van-action-sheet v-model="showFeedbackPanel" title="" :closeable="false">
      <div class="content">
        <van-row>
          <span style="font-weight:600;font-size:20px">
            匿名评价{{currentFeedbackTypeText}}
          </span>
        </van-row>
        <van-row style="padding:30px">
          <van-rate
            gutter="20"
            v-model="feedbackDetail.rate_star"
            :size="25"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
          />
        </van-row>
        <multiSelectTags :titles.sync="filtered_feedback_tags" 
          v-on:selection-update="onSelectionUpdate" :selectStates="feedback_selection_states"></multiSelectTags>
        <van-row type="flex">
          <van-field type="textarea" label="" rows="3"  
            placeholder="还有什么要补充的，可以写在这里～" v-model="feedbackDetail.feedback_content"/>
        </van-row>
        <van-row>
          <van-button type="info" @click="submitFeedback()" round class="action_btn" color="#395EE8">提交</van-button>
        </van-row>
      </div>
    </van-action-sheet>
    <div class="action_bg" v-if="showFeedbackBtn">
      <van-button type="info" round class="action_btn"  
        @click="feedbackTypeSelectShow=true" color="#395EE8">添加评价</van-button>
    </div>
    <van-action-sheet  v-model="feedbackTypeSelectShow" :actions="feedbackTypeSelectActions" 
      cancel-text="取消" description="选择要评价的对象"
      @select="onFeedbackTypeSelect"/>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import guideInfo from "@/views/components/GuideInfo";
import tourInfoTop from "@/views/components/TourInfoTop"
import {mapGetters} from 'vuex'
import multiSelectTags from "@/views/components/MultiSelectTags"
import api from '@/services/apis.js'
import titleLink from "@/views/components/TitleLink"
export default {
  components: {
    card,
    guideInfo,
    tourInfoTop,
    multiSelectTags,
    titleLink
  },
  data: () => ({
    feedbackDetail: {
      feedback_type: '1',
      feedback_content: "",
      rate_star: 0,
      feedbackable_type: "Guide",
      feedbackable_id: 0,
      feedback_type: 1,
      travel_list_id: 0,
      tag_ids: [],
    },
    loading: false,
    showFeedbackPanel: false,
    filtered_feedback_tags: [],
    feedback_selection_states: [],
    feedbackTypeSelectShow: false,
    feedbackTypeSelectActions: [
      {name: '导游', value: 'Guide'},
      {name: '行程', value: 'TravelList'},
      {name: '旅行社', value: 'TravelCompany'},
    ],
    currentFeedbackTypeText: ""
  }),
  computed: {
    ...mapGetters('travel_list', ['currentTravelList', 'currentTourBackup']),
    ...mapGetters('feedback', ['feedback_tags']),
    ...mapGetters('guider', {
      guide: 'getCurrentGuider'
    }),
    showFeedbackBtn() {
      return this.currentTravelList.status === 2 
      || this.currentTravelList.status === 3 
    },
    currentNodeIdx() {
      if(this.currentTravelList.status === 0 && this.currentTravelList.travel_checkins.length === 0) {
        return -1
      }
      if(this.currentTravelList.status === 3 ) {
        return this.currentTravelList.travel_checkins.length - 1
      }
      return this.currentTravelList.travel_checkins.length
    },
  },
  created() {
    this.refresh()
    this.$store.dispatch('feedback/get_feedback_tags')
    this.feedbackDetail.feedbackable_id = this.currentTravelList.guide_id
  },
  methods: {
    onClickBack() {
      this.$router.push('/tour/main');
    },
    submitTourRate() {
      this.showTourRating = false
      this.showGuiderRating = true
    },
    submitFeedback() {
      const _self = this
      this.feedbackDetail.travel_list_id = this.currentTravelList.id
      switch (this.feedbackDetail.feedbackable_type) {
        case "Guide": _self.feedbackDetail.feedbackable_id = _self.currentTravelList.guide_id
          break
        case "TravelList": _self.feedbackDetail.feedbackable_id = _self.currentTravelList.id
          break
        case "TravelCompany": _self.feedbackDetail.feedbackable_id = _self.currentTravelList.list_company.id 
          break

      }
      api.send_feedback(this.feedbackDetail).then((res) => {
        if(res.status === 200) {
          _self.showFeedbackPanel = false
          _self.feedbackTypeSelectShow = false
        }
      })
    },
    refresh() {
      const tourId = this.$route.params.id
      const _self = this
      this.$store.dispatch('travel_list/getTravelListInfoById', tourId).then((res) => {
        if(res.status === 200) {
          _self.$store.dispatch('guider/getGuiderById', res.data.guide_id).then((guideRes) => {
            if(guideRes.status === 200) {
              _self.loading = false
            }
          })
        }
      })
      this.$store.dispatch('travel_list/getCurrentTourBackup', tourId)
    },
    onFeedbackClick(type) {
      const _self = this
      this.feedbackDetail.feedbackable_type = type
      this.showFeedbackPanel = true
      this.cleanFeedBack(type)
      this.feedback_tags.data.forEach((tags) => {
        if(tags.type === _self.feedbackDetail.feedbackable_type) {
          _self.filtered_feedback_tags = tags.value
          _self.feedback_selection_states = tags.value.map((tag) => {return false})
        }
      })
    },
    onFeedbackTypeSelect(item) {
      this.currentFeedbackTypeText = item.name
      this.onFeedbackClick(item.value)
    },
    onSelectionUpdate(value) {
      this.feedbackDetail.tag_ids = value
    },
    cleanFeedBack(type) {
      this.feedbackDetail = {
        feedback_type: '1',
        feedback_content: "",
        rate_star: 0,
        feedbackable_type: type,
        feedbackable_id: 0,
        feedback_type: 1,
        travel_list_id: 0,
        tag_ids: []
      }
    },
    toTousuPage(){
      this.$router.push({path: '/tousu/edit', query: {id: this.currentTravelList.id}})
    }
  }
}
</script>

<style scoped>
.row {
  font-size: 14px;
  color: grey;
}
.title-text {
  font-size: 25px; color: white;font-weight:bold;margin-left:.1rem;
}
.content {
  padding: 16px 16px 160px;
  text-align: center;
}
.tour_title {
  font-size: .3rem;
  font-weight: bold;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  padding-top: 10px;
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 666;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 4rem;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    90deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 4rem;
  right: -0.2rem;
  border-radius: 50%;
}
[v-cloak] {
  display: none;
}
</style>